footer {
    width: 100%;
    margin: 0;
    .footer {
        background: #444648;;
        height: max-content;
        width: 100%;
        // margin-top: -1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            font-size: 1.4rem;
            margin: 0;
            font-family: cursive;
            font-weight: 900;
            color: whitesmoke;
            text-transform: capitalize;
            padding: 2rem 0;
            letter-spacing: 0.5px;
            @media (min-width: 1025px) and (max-width: 1300px) {
                font-size: 1.2rem;
             }
             @media (min-width: 760px) and (max-width: 1024px) {
                font-size: 1.1rem;
             }
             @media (min-width: 480px) and (max-width: 759px) {
                font-size: .9rem;
                text-align: center;
                padding: .9rem .3rem;
            }
            @media (max-width: 479px) {
                font-size: .9rem;
                text-align: center;
                padding: .7rem .2rem;
            }
            span {
                animation: heartBeat 2.5s infinite;
                display: inline-block;
            }
        }
    }
}

@keyframes heartBeat {
    0% {
      transform: scale(1);
    }
  
    14% {
      transform: scale(1.3);
    }
  
    28% {
      transform: scale(1);
    }
  
    42% {
      transform: scale(1.3);
    }
  
    70% {
      transform: scale(1);
    }
  }